.vertical-menu-header {
  @include flexColumnStart;
  background: $black;
  color: $white;
  gap: 1rem;
  height: 15.6rem;
  padding: 1rem 1rem 2.2rem 1rem;
  width: 100%;

  &__top {
    @include flexRowStart;
    gap: 1rem;
    width: 100%;

    &__select {
      @include flexRowStart();
      gap: 1rem;
      cursor: pointer;
    }

    &__text {
      @include flexRowStart();
      @include openSansSemiBold;
      color: $white;
      font-size: 1.4rem;
      gap: 0.4rem;
      line-height: 1.0rem;
    }
  }

  .Notifications {
    align-self: flex-end;
    margin-left: auto;
  }
}
