.StartWork {
  @include flexColumnCenter;
  background-image: url("../../assets/images/landscape-bg.jpg");
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;

  &__Container {
    @include flexColumnBetween;
    height: 31rem;
    width: 31.7rem;
  }
}
