.StartWorkAvatar {
  border: 0.3rem solid #ffffff26;
  border-radius: 100%;
  cursor: pointer;
  position: absolute;
  top: 3.6rem;
  right: 3.6rem;

  &__Popover {
    border-radius: 0.4rem;
    .ant-popover-content,
    .ant-popover-inner,
    .ant-popover-inner-content {
      border-radius: 0.4rem;
    }

    .ant-popover-inner-content {
      padding: 0.8rem 0;
    }

    &__Content {
      &__Item {
        @include flexRowStart;
        @include openSansRegular;
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 1.9rem;
        padding: 0.6rem 0.8rem 0.7rem 0.8rem;
        gap: 0.8rem;

        .anticon {
          color: #0000007f;
          font-size: 2rem;
        }
      }
    }
  }
}
