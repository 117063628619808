.UserWorkDaySummaryListGroupedByDate {
  &--Empty {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .UserWorkDaySummaryItemGroupedByDate {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
