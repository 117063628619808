/**
* Ant Design Style Override
*/
.ant-layout-sider-children {
  background: $black;
}
.ant-layout-header {
  height: fit-content;
  padding: 0 !important;
  position: relative;
  z-index: 2;

  .ant-page-header {
    padding: 0.4rem 0.8rem 0.4rem 1.2rem !important;

    .ant-page-header-heading {
      .ant-page-header-heading-extra {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .ant-avatar {
        margin-right: 0 !important;
      }
    }
  }
}
.ant-layout-sider:first-child {
  flex: 0 0 auto !important;
  max-width: 25.6rem !important;
  min-width: 25.6rem !important;
  width: 25.6rem !important;
}
.ant-layout-sider:last-child {
  min-width: fit-content !important;
  width: fit-content !important;
}
.ant-popover-arrow {
  display: none !important;
}
.ant-btn {
  .anticon-caret-down {
    margin-left: 3.5rem !important;
  }
}

.ant-tabs-tab {
  color: $dark-gray !important;
  padding: 1rem 1.2rem !important;

  &.ant-tabs-tab-active {
    > * {
      color: $black !important;
      font-weight: 600 !important;
    }
  }
}

.ant-tooltip {
  max-width: fit-content;
  padding-top: 0;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      @include openSansRegularSM();
      background-color: $black;
      border-radius: 0.4rem;
      color: $white;
      font-size: 1rem;
      white-space: nowrap;
      margin-bottom: -1rem;
      padding: 0.8rem;
    }
  }
}

.ant-modal-wrap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-btn-primary {
  background-color: $blue;
  border-color: $blue;
  border-radius: 0.4rem;

  &:active {
    background-color: darken($color: $blue, $amount: 10%);
    border-color: darken($color: $blue, $amount: 10%);
  }
  &:focus,
  &:hover {
    background-color: lighten($color: $blue, $amount: 10%);
    border-color: lighten($color: $blue, $amount: 10%);
  }
}

.ant-spin {
  color: $dark-gray;
}

// ant notification
.ant-notification {
  bottom: 2.4rem; // 4rem offset by the 16px margin spacing between notifications
  margin: 0 auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
/**
* /Ant Design Style Override
*/

.Snackbar {
  background-color: #000000;
  border-radius: 0.4rem;
  padding: 0.8rem 0.8rem 0.8rem 4.8rem !important;
  width: 40rem;

  &__Undo {
    font-size: 2rem;
    position: absolute;
    right: 8px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .ant-notification-notice-content {
    > div {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;

      .ant-notification-notice-message {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 55%;
        white-space: nowrap;
      }

      .ant-notification-notice-message,
      .ant-notification-notice-description {
        @include openSansRegular;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }

  .ant-notification-notice-close {
    left: 8px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    .ant-notification-close-x {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .anticon {
        font-size: 2rem;
      }
    }
  }

  .ant-notification-notice-message,
  .ant-notification-notice-description,
  .ant-notification-notice-close {
    color: #ffffff !important;
  }
}

* {
  // Default value
  // box-sizing: content-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: $font-size-large;
  line-height: 1.6;

  @media (min-width: $tablet-breakpoint) {
    font-size: $font-size-medium;
  }
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  background-color: $off-white;
  width: 16px;
}
::-webkit-scrollbar:horizontal {
  background-color: $off-white;
  height: 16px;
}
::-webkit-scrollbar-thumb {
  background-color: $scroll-bg;
  background-clip: padding-box;
  border-radius: 1rem;
  border: 4px solid transparent;
}
::-webkit-scrollbar-track {
  border-left: 1px solid $gray;
  background-color: $off-white;
}

.hidden {
  visibility: hidden;
}

.is-active {
  font-weight: bold;
}

.View {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100vw;
}

.blue-btn {
  background: $blue;
  border: darken($color: $blue, $amount: 2%) 1px solid;
  border-radius: 0.4rem;
  transition: all ease 0.3s;
  &:hover,
  &:focus {
    background: darken($color: $blue, $amount: 2%);
    border: darken($color: $blue, $amount: 4%) 1px solid;
  }
}

.green-btn {
  background: $light-green;
  border: darken($color: $light-green, $amount: 2%) 1px solid;
  border-radius: 0.4rem;
  &:hover,
  &:focus {
    background: darken($color: $light-green, $amount: 2%);
    border: darken($color: $light-green, $amount: 4%) 1px solid;
  }
}

.orange-btn {
  background: $orange;
  border: darken($color: $orange, $amount: 2%) 1px solid;
  border-radius: 0.4rem;

  &:hover,
  &:focus {
    background: darken($color: $orange, $amount: 2%);
    border: darken($color: $orange, $amount: 4%) 1px solid;
  }
}

.logout-button {
  background: black;
  border: black;
  border-radius: 0.4rem;
  &:hover,
  &:focus {
    background: black;
    border: black;
  }
}

.is-disabled,
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.full-width {
  width: 100%;
}

.vertical-center {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
