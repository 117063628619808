// Colors
$white: #ffffff;
$white-99: #ffffff99;
$black: #000000;
$black-99: #00000099;
$orange: #ffbf00;
$dark-orange: #ff8000;
$light-pink: #f18ecd;
$blue: #0079f2;
$light-blue: #0079f21a;
$dark-pink: #e94baf;
$red: #ff2900;
$light-red: #ffe9e5;

$warning: #ffaa00;
$text-highlight: #ffd500;

$off-white: #f7f7f7;
$scroll-bg: #d2d2d2;

$light-gray: #ffffff80;
$gray: #00000026;
$dark-gray: #0000007f;
$light-green: #40bf00;
$dark-blue: #004d99;
$hr-gray: #252525;

// Menu
$menuTextColor: #ffffffcc;
$menuItemSelectedColor: #262626;
$subMenuItemSelectedColor: #464646;

// Font Sizes
$jumbotron: 16rem;
$font-size-large: 1.8rem;
$font-size-medium: 1.6rem;
$font-size-small: 1.4rem;
$font-size-x-small: 0.9rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

// Breakpoints
$tablet-breakpoint: 48rem;
$desktop-breakpoint: 76.8rem;

$calculated-height: calc(100vh - 5.7rem);

@mixin hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin calculatedHeight {
  min-height: calc(100vh - 5.7rem);
}

@mixin badgeCount {
  @include openSansRegularSM();
  color: #ffffff7f;
  float: right;
  font-size: 1.2rem;
  margin-left: auto;
  min-width: 3.2rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;

  > sup {
    background-color: $menuItemSelectedColor;
    border-radius: 0.4rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.5;
  }
}

// Flex
@mixin flexRow {
  align-items: center;
  display: flex;
  flex-direction: row;
}
@mixin flexRowStart {
  @include flexRow;
  justify-content: flex-start;
}
@mixin flexRowCenter {
  @include flexRow;
  justify-content: center;
}
@mixin flexRowBetween {
  @include flexRow;
  justify-content: space-between;
}
@mixin flexRowEnd {
  @include flexRow;
  justify-content: flex-end;
}

@mixin flexColumn {
  align-items: center;
  display: flex;
  flex-direction: column;
}
@mixin flexColumnCenter {
  @include flexColumn;
  align-items: center;
  justify-content: center;
}
@mixin flexColumnBetween {
  @include flexColumn;
  justify-content: space-between;
}
@mixin flexColumnStart {
  @include flexColumn;
  align-items: flex-start;
  justify-content: flex-start;
}

// Character styles
@mixin openSansRegular {
  letter-spacing: 0;
  line-height: 1.9rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

@mixin openSansRegularXS {
  color: $white;
  letter-spacing: 0;
  line-height: 1.3rem;
  font-family: "Open Sans", sans-serif;
  font-size: $font-size-x-small;
  font-weight: 400;
}

@mixin openSansRegularSM {
  letter-spacing: 0;
  line-height: 1.9rem;
  font-family: "Open Sans", sans-serif;
  font-size: $font-size-small;
  font-weight: 400;
}

@mixin openSansItalic {
  color: $dark-gray;
  letter-spacing: 0;
  line-height: 1.9rem;
  font-family: "Open Sans", sans-serif;
  font-size: $font-size-small;
  font-weight: 400;
  font-style: italic;
}

@mixin openSansSemiBold {
  color: $black;
  letter-spacing: 0;
  line-height: 1.9rem;
  font-family: "Open Sans", sans-serif;
  font-size: $font-size-small;
  font-weight: 600;
}

@mixin openSansBold {
  color: $black;
  letter-spacing: 0;
  line-height: 2.2rem;
  font-family: "Open Sans", sans-serif;
  font-size: $font-size-medium;
  font-weight: 700;
}

@mixin customPopover {
  .ant-popover {
    background-color: $black;
    border-radius: 0.4rem;
    padding-bottom: 0;
    text-align: center;
    white-space: nowrap;

    &.ant-popover-placement-bottom,
    &.ant-popover-placement-bottomRight {
      padding-top: 0;
    }

    .ant-popover-content {
      background-color: $black;
      border-radius: 0.4rem;

      .ant-popover-inner-content {
        background-color: $black;
        border-radius: 0.4rem;
        padding: 0.5rem;

        > div {
          @include openSansRegularSM();
          background-color: $black;
          color: $white;
          font-size: 1rem;
        }
      }
    }
  }
}

@mixin controlIcon {
  @include flexCenter();
  @include grayBorder();
  border-radius: 0.4rem;
  color: $dark-gray;
  cursor: pointer;
  height: 2.4rem;
  padding: 0.1rem;
  width: 2.4rem;
}

@mixin flexCenter {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin flexAlignCenter {
  align-items: center;
  display: flex;
}

@mixin grayBorder {
  border: 1px solid $gray;
}

@mixin defaultBtn {
  background: #0000000d;
  border: 1px solid #0000000d;
  color: #0000007f;
}

@mixin disabledBtn {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;

  .anticon {
    svg {
      color: inherit;
    }
  }
}

@mixin successBtn {
  background: $light-green;
  border: none;
  color: $white;
  text-decoration: none;

  .anticon {
    svg {
      color: $white;
    }
  }
}

@mixin blueBtn {
  background: $blue;
  color: $white;

  .anticon {
    svg {
      color: $white;
    }
  }
}

@mixin iconOnlyBtn {
  background: $white;
  border: 1px solid $gray;
  padding: 0.3rem 0 0 0;
  svg {
    color: inherit;
  }
}

@mixin workTimeWidget {
  width: 100%;

  &__Title {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.2rem;

    &__Text {
      @include openSansSemiBold;
      font-size: 1.2rem;
      letter-spacing: 0.09rem;
      line-height: 1.7rem;
      margin-right: 0.4rem;
      text-transform: uppercase;
    }
    &__Icon {
      color: $gray;
      cursor: pointer;
    }
  }
  &__Content {
    align-items: center;
    border: 1px solid $gray;
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.9rem);
    justify-content: center;
    padding: 1.2rem;
    height: 15.4rem;

    &__Session {
      width: 100%;
    }

    &__Data,
    .ThirdPartyTracking__Content__Session__Data {
      @include openSansRegular;
      align-items: center;
      display: flex;
      font-size: 1.4rem;
      justify-content: space-between;
      line-height: 1.9rem;
      width: 100%;

      &--Centered &__Title {
        @include openSansRegular;
        font-size: 2.4rem;
        line-height: 3.3rem;
        margin: auto;
        text-align: center;
        width: 100% !important;
      }

      &--Primary {
        @include openSansSemiBold;
      }

      &--Secondary &__Title {
        margin-left: 1.2rem;
      }

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    &__Notes {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &__Title {
        @include openSansSemiBold;
        margin-bottom: 0.8rem;
        text-align: left;
        width: 100%;
      }
      &__Text {
        @include openSansRegular;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid $gray;
    margin: 0;
    margin-bottom: 0.8rem;
    width: calc(100% + 2.4rem);
  }
}

@mixin customModal {
  .ant-modal-content {
    border-radius: 0.4rem;
    position: relative !important;

    .ant-modal-close {
      color: $white;
      float: right;
      position: sticky;
      margin-right: -3.6rem;
      margin-top: -1.2rem;

      .ant-modal-close-x {
        height: 2.4rem;
        line-height: normal;
        width: 2.4rem;

        .anticon {
          > svg {
            font-size: 2.4rem;
          }
        }
      }
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-body {
      border-radius: 0.4rem;
      padding: 0;
    }
  }
}

@mixin selection {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &__Item {
    &:first-child {
      flex-grow: 1;
    }
    &:not(:first-child) {
      margin-left: 1.6rem;
    }

    &__Title {
      @include openSansSemiBold;
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 0.8rem;
    }

    &__Dropdown {
      align-items: center;
      border: 1px solid $gray;
      border-radius: 0.4rem;
      cursor: pointer;
      display: flex;
      padding: 0.6rem;
      justify-content: space-between;

      &__Selected {
        align-items: center;
        display: flex;
        justify-content: flex-start;

        .ProjectInitial {
          margin-right: 0.8rem;
        }

        &__Title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      > .anticon {
        font-size: 2rem;
      }
    }
  }
}

@mixin selectionDropdownMenu {
  border: 1px solid $gray;
  border-radius: 0.4rem;
  max-height: 15rem;
  overflow: auto;
  width: 100%;

  .ant-dropdown-menu-item {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .ProjectInitial {
      margin-right: 0.8rem;
    }

    > span {
      @include openSansRegular();
      font-size: 1.4rem;
      line-height: 1.9rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@mixin markAsReadBtn {
  @include openSansRegular;
  background-color: $white;
  border-radius: 0.4rem;
  border: 1px solid $gray;
  color: $black;
  font-size: 1.2rem;
  height: 2.4rem;
  line-height: 1.7rem;
  padding: 0.3rem 0.8rem 0.4rem 0.8rem;

  &:hover {
    cursor: pointer;
  }
}

@mixin antCollapsePanelOverride {
  > .ant-collapse {
    border: 0;
    > .ant-collapse-item {
      background-color: $white;
      border: 0;
      > .ant-collapse-header {
        border: 0 !important;
        background-color: $white;
        margin-bottom: 1rem;
        padding: 0;
      }

      > .ant-collapse-content {
        border: 0;
        > .ant-collapse-content-box {
          border: 0;
          padding: 0;
        }
      }
    }
  }
}
@mixin checkoutTimeBarTooltip {
  .ant-tooltip-content {
    position: relative;
    .ant-tooltip-inner {
      padding: 1.6rem 1.2rem !important;
      .CheckoutTimeBarTooltip {
        @include openSansRegular;
        align-items: flex-start;
        color: $off-white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }
    }
  }
}

@mixin customButton($color, $textColor) {
  background-color: $color;
  border: 1px solid #f2f2f2;
  color: $textColor;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #f2f2f2 !important;
  }

  .anticon {
    svg {
      color: currentColor;
    }
  }
}

@mixin customPopover {
  .ant-popover-content {
    border-radius: 0.4rem;

    .ant-popover-inner {
      border-radius: 0.4rem;
      box-shadow: 0px 0px 3px #00000026;

      .ant-popover-inner-content {
        border-radius: 0.4rem;
        padding: 0 !important;
      }
    }
  }
}

@mixin customTooltip {
  .ant-tooltip-content {
    border-radius: 0.4rem;

    .ant-tooltip-inner {
      border-radius: 0.4rem;
      box-shadow: 0px 0px 3px #00000026;
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}

.customBlockQuote {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  color: #999;
  font-family: "Hoefler Text", Georgia, serif;
  font-style: italic;
  text-align: center;

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.richTextCustomLink {
  color: #3b5998;
  text-decoration: underline;
}

@mixin memberForm {
  position: relative;
  width: 100%;

  &__Title {
    @include openSansSemiBold;
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
  }

  .ant-form {
    border: 1px solid $gray;
    border-radius: 0.4rem;
    padding: 0 1.6rem;

    .ant-form-item {
      margin-bottom: 0;
      padding: 1.2rem 0;

      > .ant-form-item-label {
        @include openSansSemiBold;
        font-size: 1.4rem;
        line-height: 1.9rem;
        text-align: left;
        > label::after {
          content: none;
        }
      }

      > .ant-form-item-control {
        justify-content: flex-start;
      }

      > * {
        flex-basis: 50%;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: "";
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }

    .ant-switch {
      min-width: 4rem;

      &:focus,
      &:hover,
      &:active {
        box-shadow: none;
      }
    }

    .ant-switch-checked {
      background-color: $light-green;

      .ant-switch-handle {
        left: calc(100% - 1.6rem - 0.4rem);
      }
    }

    .ant-switch-handle {
      height: 1.6rem;
      left: 0.4rem;
      top: 0.3rem;
      width: 1.6rem;
    }
  }
}
