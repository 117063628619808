.StartWorkTimeDisplay {
  @include openSansRegular;
  color: white;
  height: 21.8rem;
  width: fit-content;

  &__Time {
    font-size: 16rem;
    line-height: 21.8rem;
  }
}
