.LoginView {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;

  .ant-input-prefix {
    .anticon {
      color: $dark-gray;
      font-size: 2rem;
    }
  }
}
