.WorkTimeRecordTable {
  &__Header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;

    &__Title {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      &__Text {
        @include openSansSemiBold;
        font-size: 1.2rem;
        letter-spacing: 0.09rem;
        line-height: 1.7rem;
        margin-right: 0.4rem;
        text-transform: uppercase;
      }
    }

    &__Actions {
      align-items: center;
      display: flex;
      justify-content: center;

      &__Item {
        background-color: $white;
        border: 1px solid $gray;
        color: $gray;
        cursor: pointer;
        font-size: 1.2rem;
        padding: 0.4rem;

        &:hover,
        &--Active {
          color: $blue;
        }

        &--Disabled {
          background-color: $off-white;
          color: $dark-gray;
        }

        &:first-child {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }

        &:last-child {
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }

        &:not(:first-child) {
          border-left: 0;
        }
      }
    }
  }

  &__Table {
    border: 1px solid $gray;
    border-radius: 0.4rem;

    .ant-empty {
      .ant-empty-img-simple,
      .ant-empty-description {
        display: none !important;
      }
    }

    .ant-table {
      border-radius: 0.4rem;
      > .ant-table-container {
        border-radius: 0.4rem;
        > .ant-table-content {
          border-radius: 0.4rem;
          > table {
            border-radius: 0.4rem;
            > .ant-table-thead {
              border-radius: 0.4rem;

              th {
                @include openSansSemiBold;
                background-color: $white;
                font-size: 1.4rem;
                line-height: 1.9rem;
                padding-top: 1rem;
                padding-bottom: 1.1rem;
                text-align: center;
                white-space: nowrap;

                // remove the separator
                &::before {
                  content: none;
                }
              }
            }

            > .ant-table-summary {
              @include openSansSemiBold;
              font-size: 1.4rem;
              text-align: right;

              .ant-table-cell {
                padding-top: 1rem !important;
                padding-bottom: 1.1rem !important;
              }

              .ant-table-cell:last-child {
                padding-right: 2.5rem !important;
              }
            }
          }
        }
      }
    }

    &--standard {
      .ant-table-thead > tr > th,
      .ant-table-row td,
      .ant-table-summary td {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(6),
        &:nth-child(8) {
          border-right: 1px solid $gray;
        }
      }
    }
    &--detailed {
      .ant-table-row td,
      .ant-table-summary td {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(6),
        &:nth-child(9) {
          border-right: 1px solid $gray;
        }
      }
      .ant-table-thead {
        > tr {
          > th {
            &:not(:last-child) {
              border-right: 1px solid $gray;
            }

            font-size: 1.2rem !important;

            @media (min-width: 1400px) {
              font-size: 1.4rem !important;
            }
          }
          &:not(:first-child) {
            > th {
              border-right: none;
              &:nth-child(4),
              &:nth-child(7) {
                border-right: 1px solid $gray;
              }
            }
          }
        }
      }
      .ant-table-tbody {
        .WorkTimeRecordTable__Table__Data {
          font-size: 1.2rem !important;

          @media (min-width: 1400px) {
            font-size: 1.4rem !important;
          }
        }
      }
      .ant-table-summary {
        font-size: 1.2rem !important;

        @media (min-width: 1400px) {
          font-size: 1.4rem !important;
        }
      }
    }

    &__Data {
      @include openSansRegular;
      align-items: center;
      display: flex;
      // font-size: 1.2rem;
      justify-content: flex-end;
      line-height: 1.9rem;
      white-space: nowrap;

      // @media (min-width: 1400px) {
      //   font-size: 1.4rem;
      // }

      &--Inactive {
        color: #0000003f;
      }

      &--InTheOffice,
      &--Tracked,
      &--Schedule {
        align-items: flex-end;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      &--InTheOfficeShiftsData,
      &--TrackedHoursData,
      &--ScheduleShiftsData,
      &--ScheduleHoursData {
        align-items: flex-end;

        > div {
          align-items: center;
          display: flex;
          justify-content: flex-start;

          &:not(:last-child) {
            margin-bottom: 0.6rem;
          }

          > *:not(:last-child) {
            margin-right: 0.6rem;
          }
        }
      }
      &--InTheOfficeShiftsData {
        align-items: flex-start;
        display: flex;
        justify-content: center;

        &__ShiftSpan {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 8.5rem;

          > * {
            &:first-child,
            &:last-child {
              width: 3.6rem;
            }
            &:first-child {
              text-align: right;
            }
            &:last-child {
              text-align: left;
            }
          }
        }
      }
      &--TrackedManualData {
        align-items: flex-end;
      }

      &--DateData {
        justify-content: flex-start;
      }
      &--MemberData {
        justify-content: flex-start;

        .CustomAvatar {
          margin-right: 0.8rem;
        }
      }
      &--WorkHoursData {
        justify-content: flex-start !important;

        &--Active {
          color: $blue;
          cursor: pointer;
        }

        &.WorkTimeRecordTable__Table__Data--Inactive {
          justify-content: flex-end !important;
          pointer-events: none;
        }

        > * {
          &:not(:last-child) {
            margin-right: 0.6rem;
          }
        }
      }
      &--ShiftData {
        align-items: center;
        justify-content: flex-start;

        > * {
          &:not(:last-child) {
            margin-right: 0.6rem;
          }
        }
      }
      &--OfficeHoursData {
      }
      &--BreakTimeData {
      }
      &--ActivityData {
      }
      &--ScheduleData {
        > * {
          &:not(:last-child) {
            margin-right: 0.6rem;
          }
        }
      }

      &__Tags {
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;

        > * {
          margin-left: 0.6rem;
        }
      }

      &__Status {
        margin-right: 0.5rem;
        &--Success {
          color: $light-green;
        }
        &--Warning {
          color: $warning;
        }
        &--Danger {
          color: $red;
        }
      }
    }
  }
}
