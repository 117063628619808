.InTheOfficeBtn {
  width: 100%;
  
  .InTheOfficeBtn__Btn {
    @include openSansRegular;
    align-items: center;
    background-color: #ffffff40;
    border-radius: 0.4rem;
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    justify-content: space-between;
    line-height: 1.9rem;
    padding: 0.6rem;

    > div {
      align-items: center;
      display: flex;

      .InTheOfficeBtn__Btn__OfficeIcon {
        color: $light-green;
        margin-right: 0.8rem;
      }
    }
  }
}

.InTheOfficeBtn__Popover {
  width: 92.1875%;
  padding-top: 0;

  .ant-popover-inner {
    background-color: transparent;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
