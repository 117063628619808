.Office {
  background: #fff;
  position: relative;
  width: 100%;

  &__header {
    padding: 1.2rem;
    font-size: 1.8rem;
    font-weight: 600;
    height: 5.6rem;
  }

  &__divider {
    margin-top: -1px;
    border-color: $gray;
  }

  &__info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    margin: 0 4rem 2rem;
    padding: 1.6rem;
    color: #000;
    border: 1px solid $gray;
    border-radius: 6px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }

  &__name,
  &__summary-time {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
  }

  &__title,
  &__summary-desc {
    font-size: 1.2rem;
  }

  &__summaries {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.6rem 4rem;

    &__loading {
      flex-basis: 100%;
      flex: 1;
    }
  }

  &__summary {
    flex: 0 0 48%;
    display: flex;
    flex-direction: column;

    &-header {
      @include flexRowBetween;
      align-items: center;
      margin-bottom: 1.2rem;

      > h2 {
        @include openSansSemiBold;
        font-size: 1.2rem;
        letter-spacing: 0.09rem;
        line-height: 1.7rem;
        margin-bottom: 0;
        text-transform: uppercase;
      }

      > div {
        @include flexRowEnd;
        gap: 0.8rem;
      }

      &__timestamp {
        @include openSansRegular;
        font-size: 1.2rem;
        line-height: 1.7rem;
      }

      &__refresh-wrapper {
        @include flexRowCenter;
        align-self: center;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.4rem;
        cursor: pointer;
        height: fit-content;
        width: fit-content;
        padding: 0.2rem;
      }

      &__refresh-btn {
        font-size: 1.6rem;
      }
    }
  }

  &__summary-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.6rem;
    color: #000;
    border: 1px solid $gray;
    border-radius: 6px;
  }

  &__summary-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__empty {
    @include flexColumnCenter;
    @include openSansRegular;
    color: $dark-gray;
    font-size: 1.4rem;
    line-height: 1.9rem;
    width: 100%;
    height: 4.8rem;
  }
}

@media (max-width: 912px) {
  .Office {
    &__summaries {
      flex-direction: column;
    }

    &__summary {
      width: 100%;
      margin-bottom: 2.6rem;
    }
  }
}
