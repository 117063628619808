.PageEmptyState {
  align-items: center;
  background: $white;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  width: 100%;

  &__Container {
    height: 16.5rem;
    width: 35.8rem;
    text-align: center;
  }

  &__Icon {
    font-size: 8rem;
  }

  &__Title {
    @include openSansSemiBold;
    color: $black;
    display: block;
    font-size: 2rem;
    line-height: 2.7rem;
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
  }

  &__Content {
    @include openSansRegular;
    color: $black;
    font-size: 1.4rem;
    line-height: 2.1rem !important;
  }
}
