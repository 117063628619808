.login-form {
  //   height: 73.828125vh;
  height: fit-content;
  padding: 2.4rem;
  position: relative;
  width: fit-content;
  //   width: 43.92386530014641vw;

  &__blue-box {
    background: $blue;
    border-radius: 0.4rem;
    height: 16.7rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 16.7rem;
  }

  &__light-blue-box {
    background: #00d4ff;
    border-radius: 0.4rem;
    height: 16.7rem;
    position: absolute;
    top: 1.2rem;
    left: 7.1rem;
    width: 16.7rem;
  }

  &__orange-box {
    background: $warning;
    border-radius: 0.4rem;
    height: 12.8rem;
    position: absolute;
    bottom: 1.2rem;
    right: 1.2rem;
    width: 12.8rem;
  }

  &__container {
    @include flexColumnCenter;
    align-items: center;
    background: $white;
    justify-content: center;
    border-radius: 0.4rem;
    box-shadow: 0px 0px 8px #0000001a;
    gap: 3.6rem;
    height: 54.3rem;
    max-height: 54.3rem;
    max-width: 57.6rem;
    min-width: 36.9rem;
    padding: 4.8rem 6.73499267935578vw;
    position: relative;
    width: 42.16691068814056vw;
  }

  &__title {
    @include openSansSemiBold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 1.2rem;
  }

  &__header {
    @include flexColumnCenter;
    @include openSansSemiBold;
    font-size: 2.4rem;
    gap: 1.6rem;
    line-height: 3.3rem;
    text-align: center;

    > small {
      @include openSansRegular;
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
  }

  .ant-form {
    height: fit-content;
    width: 100%;

    .ant-form-item-explain-error {
      font-size: 1.2rem;
    }

    button[type="submit"] {
      width: 100%;
    }
  }
}

@media (min-width: 1366px) {
  .login-form {
    &__container {
      padding: 4.8rem 9.6rem;
    }
  }
}