.vertical-menu {
  @include flexColumnStart;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    -ms-overflow-style: auto; /* IE and Edge */
    scrollbar-width: auto; /* Firefox */
    &::-webkit-scrollbar {
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d2d2d2;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    background-color: transparent;
    width: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 1rem;
    border: 0.4rem solid transparent;
  }
  &::-webkit-scrollbar-track {
    border-left: 0;
    background-color: transparent;
  }

  &__menu {
    background: $black !important;
    flex: 1 1 100%;
    padding: 0.6rem 0 0.9rem 0;
  }

  &__item {
    background: $black;
    padding: 1rem !important;
    margin: 0 !important;

    &__content {
      @include flexRowStart;
    }

    &__badge {
      @include flexRowCenter;
      @include openSansRegular;
      justify-self: center;
      background: #ffffff26 !important;
      border-radius: 0.4rem;
      font-size: 1.2rem;
      height: 1.8rem;
      line-height: 1.7rem;
      margin-left: auto;
      width: 3.2rem;
    }

    .ant-menu-item-icon {
      font-size: 2rem;
    }

    &.ant-menu-item-selected {
      background: rgba(255, 255, 255, 0.15) 0% 0% no-repeat padding-box !important;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 0.3rem;
        height: 100%;
        background-color: rgba(64, 191, 0, 1) !important;
      }
    }
  }

  &__header {
    @include flexRowBetween;
    @include openSansRegular;
    color: #ffffff7f !important;
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
    padding: 1rem !important;
    pointer-events: none;
    text-transform: uppercase;

    &__actions {
      pointer-events: initial;

      .anticon {
        font-size: 1.6rem;

        &:hover {
          color: $white;
        }
      }
    }
  }

  &__sub-menu {
    padding: 0;

    .ant-menu-submenu-title {
      margin: 0 !important;
      padding: 0.8rem !important;

      > .anticon {
        font-size: 2rem;
        transition: transform 0.3s;
        transform: rotate(-90deg);
      }
    }

    &:hover,
    &--selected {
      .ant-menu-submenu-title {
        // background: #464646;
        color: #ffffffcc;
      }
    }

    &--selected__border {
      height: 100%;
      left: 0;
      position: absolute;
      width: 2px;
    }

    &--disabled {
      .anticon {
        display: none;
      }
    }

    .ProjectInitial {
      margin-right: 0.8rem;
    }

    .ant-menu-sub {
      height: fit-content;
      margin: 0 !important;
      padding: 0 !important;
    }

    &:hover .ant-menu-item-group,
    &--selected .ant-menu-item-group {
      background: #262626;
      color: #ffffffcc;
    }

    .ant-menu-item-group {
      &-title {
        display: none;
      }
      &-list {
        .ant-menu-item {
          border-left: 2px solid transparent;
          margin: 0;
          padding: 1rem 1rem 1rem 4rem !important;

          .vertical-menu__sub-menu__title {
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 80%;
          }
        }
      }
    }
  }

  .ant-menu-submenu-open > .ant-menu-submenu-title > .anticon {
    transition: transform 0.3s;
    transform: rotate(0deg);
  }

  &__beta-indicator {
    @include openSansRegular;
    align-items: center;
    background: #262626;
    bottom: 0;
    color: $white;
    display: flex;
    font-size: 1.4rem;
    gap: 0.8rem;
    height: 3.2rem;
    justify-content: center;
    line-height: normal;
    padding: 1.2rem 0;
    position: relative;
    text-align: center;
    width: 100%;
  }
}
