.HistoryTabPane {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__Header {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    right: 5.6rem;
    top: -4.4rem;

    > * {
      margin-right: 1.6rem;
    }

    &__GroupBy {
      align-items: center;
      display: flex;
      justify-content: center;

      &__Item {
        @include openSansRegular;
        border: 1px solid $gray;
        cursor: pointer;
        font-size: 1.4rem;
        height: 3.2rem;
        line-height: 1.9rem;
        padding: 0.6rem 1.2rem 0.7rem 1.2rem;
        text-transform: capitalize;

        &:first-child {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
          border-right: 0;
        }

        &:last-child {
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }

        &--Active {
          background-color: $white;
          color: $blue;
          opacity: 1;
        }

        &--Disabled {
          background-color: $off-white;
          color: $black;
          cursor: not-allowed;
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

  &__Body {
    flex: 1 1 auto;
    height: 0px;
    overflow-y: auto;
    padding: 2.4rem;
    width: 100%;

    &--Empty {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    > *:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    &__DateRangeDisplay {
      @include openSansSemiBold;
      font-size: 2rem;
      line-height: 2.7rem;
    }
  }
}
